import * as SentryAngular from "@sentry/angular";
import {environment} from "../../environments/environment";
// @ts-ignore
import packageInfo from '../../../package.json';

export class SentryInitializer {
  public static initialize(): void {

    SentryAngular.init(
      {
        environment: environment.name,
        dsn: environment.sentryDsn,
        release: packageInfo.name + "@" + packageInfo.version,
        dist: "1",
        integrations: [
          SentryAngular.browserTracingIntegration(),
          SentryAngular.replayIntegration(),
          SentryAngular.browserProfilingIntegration()
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracePropagationTargets: [window.location.origin, environment.backendUri]
      }
    );
  }
}
